(function($) {

    // var kerwee_position = $('.kerwee-logo-img').height() / 2;
    //
    // $('#kerwee-site-logo-wrapper').css({
    //     "bottom" :  "-" + kerwee_position + "px",
    // });

    var window_top = 0;

    function checkNavigation() {
        var header_content_wrapper = $('.header-content-wrapper');
        var regular_navigation_height = $(header_content_wrapper).height();

        var mobile_navigation = $('#responsive-menu-button');
        var mobile_navigation_height = $(mobile_navigation).height();

        var window_top = $(window).scrollTop();

        /*
         * Desktop Display
         */
        if ($('.nav-desktop-container').is(':visible')) {
            if (window_top >= regular_navigation_height) {
                if (!header_content_wrapper.hasClass('main-nav-altered')) {
                    /*
                     * If nav hasn't been moved
                     */
                    header_content_wrapper.fadeOut(function () {
                        header_content_wrapper.addClass('main-nav-altered');
                        header_content_wrapper.fadeIn();
                    });
                }
            }


            if (window_top < regular_navigation_height) {
                if (header_content_wrapper.hasClass('main-nav-altered')) {
                    header_content_wrapper.fadeOut(function () {
                        header_content_wrapper.removeClass('main-nav-altered');
                        header_content_wrapper.fadeIn();
                    });
                }
            }
        } //else {

            /*
             * Mobile Navigation
             */
            // var mobile_top = $('#responsive-menu-button').offset().top;
            // var y = $(window).scrollTop();
            //
            // if (y >= mobile_top && y !== 0){
            //
            //     if(!$('#responsive-menu-button').hasClass('position-fixed'))
            //     {
            //         $('#responsive-menu-button').addClass('position-fixed');
            //     }
            //     // $('#responsive-menu-container').addClass('mobile-nav-altered');
            //
            // } else if( y < mobile_top || y == 0) {
            //
            //     $('#responsive-menu-button').removeClass('position-fixed');
            //     // $('#responsive-menu-container').removeClass('mobile-nav-altered');
            //
            // }
        // }
    }

    $(window).on('scroll resize', function() {
        checkNavigation();
    });

    // var throttled = _.throttle(checkNavigation(), 1000);
    //
    // $(window).scroll(throttled);

    AOS.init();

    // var vid = document.getElementById("video-background");
    // var vidfallback = document.getElementsByClassName('video-wrap-fallback-image');

    $('#video-background').on('ended',function(){
        $(this).hide(200);
            $('.video-wrap-fallback-image').show();
        $('header.banner').removeClass('overlay');
    });

    $('#choice_1_6_0, #choice_1_6_1').on('click', function() {

        var classname = 'contact-input-checked';

        if( this.id === "choice_1_6_0" )
        {
            var li_item = $('.gchoice_1_6_0');

            if( $('.gchoice_1_6_1').hasClass(classname) )
            {
                $('.gchoice_1_6_1').removeClass(classname);
            }
        } else if( this.id === "choice_1_6_1" ) {
            var li_item = $('.gchoice_1_6_1');

            if( $('.gchoice_1_6_0').hasClass(classname) )
            {
                $('.gchoice_1_6_0').removeClass(classname);
            }
        }

        if( typeof( li_item ) !== "undefined" && li_item !== null )
        {
            li_item.addClass(classname);
        }

    });

    $('#choice_1_6_0, #choice_1_6_1, #choice_3_6_0').on('click', function() {

        var classname = 'contact-input-checked';

        if( this.id === "choice_1_6_0" )
        {
            var li_item = $('.gchoice_1_6_0');

            if( $('.gchoice_1_6_1').hasClass(classname) )
            {
                $('.gchoice_1_6_1').removeClass(classname);
            }
        } else if( this.id === "choice_1_6_1" ) {
            var li_item = $('.gchoice_1_6_1');

            if( $('.gchoice_1_6_0').hasClass(classname) )
            {
                $('.gchoice_1_6_0').removeClass(classname);
            }
        } else if( this.id === "choice_3_6_0" ) {
            console.log('hey');
            var li_item = $('.gchoice_3_6_0');

            if( $('.gchoice_3_6_0').hasClass(classname) )
            {
                $('.gchoice_3_6_0').removeClass(classname);
            }
        }

        if( typeof( li_item ) !== "undefined" && li_item !== null )
        {
            li_item.addClass(classname);
        }

    });

})(jQuery);

jQuery(document).ready(function() {



});
